import React, { useState } from 'react';
import MicRecorder from 'mic-recorder-to-mp3'

const AudioInput = ({ onStartRecording, onRecordingComplete }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));

    const startRecording = () => {
        recorder.start().then(() => {
            setIsRecording(true);
            onStartRecording(); // Notify the parent component that recording has started
        }).catch((e) => {
            console.error(e);
        });
    }

    const stopRecording = () => {

        recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
            
            setIsRecording(false);

            const file = new File([blob], 'audionote.mp3', {
                type: blob.type,
                lastModified: Date.now(),
            });

            const bitrate = 128;
            const durationInSeconds = (file.size * 8) / (bitrate * 1024);

            // Convert duration to mm:ss format
            const minutes = Math.floor(durationInSeconds / 60);
            const seconds = Math.floor(durationInSeconds % 60);
            const formattedDuration = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

            onRecordingComplete(file, formattedDuration);

        }).catch((e) => {
            alert('We could not retrieve your message');
            console.log(e);
        });
    }

    return(
        <div>
            {
                isRecording ?
                <label onClick={stopRecording} className='file-input-label'>
                    ⏹️
                </label> :
                <label onClick={startRecording} className='file-input-label'>
                    🎙️
                </label> 
            }
        </div>
    )
}

export default AudioInput;