import React, { createContext, useContext } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

const AnalyticsContext = createContext(null);

export const AnalyticsProvider = ({ children }) => {
  const analytics = AnalyticsBrowser.load({
    writeKey: 'XAxykk8yMPqd3kIblCaB2y4WbmZA2efg',
  });

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
