import React, { createContext, useState, useContext } from 'react';

const UserPreferencesContext = createContext();

export const useUserPreferences = () => useContext(UserPreferencesContext);

export const UserPreferencesProvider = ({ children }) => {
    const [preferences, setPreferences] = useState({
        language: '',
        surferType: '',
        userGoals: []
    });

    const updatePreferences = (newPreferences) => {
        setPreferences(prevPreferences => ({ ...prevPreferences, ...newPreferences }));
    };

    return (
        <UserPreferencesContext.Provider value={{ preferences, updatePreferences }}>
            {children}
        </UserPreferencesContext.Provider>
    );
};
