import React, { useState, useEffect, useRef } from 'react';
import Message from '../components/Message';
import AudioInput from '../components/AudioInput';
import ChatInput from '../components/ChatInput';
import Loader from '../components/Loader';
import { useAnalytics } from '../contexts/AnalyticsContext';
import { useUserPreferences } from '../contexts/UserPreferencesContext';

const API_URL = process.env.REACT_APP_API_URL;

const Chat = ({createNewThread, threadId}) => {

    const analytics = useAnalytics();

    const [isChatActive, setIsChatActive] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const {preferences} = useUserPreferences();
    const [message, setMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState({isRecording: false,duration: String,audioFile: null});

    const translations = {
        en: {
          letsGoSurfing: "Let's go surfing?",
          forecast6h: "Give me a 6h forecast for Las Americas and Bajamar 🏄‍♂️",
          top10Surfers: "Who are the top 10 surfers to follow in Canary Islands? 🏆",
          bestSurfSpots: "What are the best surf spots in Canary Islands? 🌊",
          purposeRayco: "What is your purpose, Rayco? 🔮",
          send: "Send",
          restartConversation: "Restart conversation 🔄",
          loadingMessage: "Loading message..."
        },
        es: {
          letsGoSurfing: "¿Vamos a surfear?",
          forecast6h: "Dame un pronóstico de 6h para Las Américas y Bajamar 🏄‍♂️",
          top10Surfers: "¿Quiénes son los 10 mejores surfistas para seguir en Canarias? 🏆",
          bestSurfSpots: "¿Cuáles son los mejores lugares para surfear en Canarias? 🌊",
          purposeRayco: "¿Cuál es tu propósito, Rayco? 🔮",
          send: "Enviar",
          restartConversation: "Reiniciar conversación 🔄",
          loadingMessage: "Cargando mensaje..."
        },
        fr: {
            letsGoSurfing: "On va surfer ?",
            forecast6h: "Donne-moi une prévision de 6h pour Las Americas et Bajamar 🏄‍♂️",
            top10Surfers: "Qui sont les 10 meilleurs surfeurs à suivre aux Îles Canaries ? 🏆",
            bestSurfSpots: "Quels sont les meilleurs spots de surf aux Îles Canaries ? 🌊",
            purposeRayco: "Quel est ton objectif, Rayco ? 🔮",
            send: "Envoyer",
            restartConversation: "Redémarrer la conversation 🔄",
            loadingMessage: "Chargement du message..."
          },
        de: {
            letsGoSurfing: "Gehen wir surfen?",
            forecast6h: "Gib mir eine 6-Stunden-Vorhersage für Las Americas und Bajamar 🏄‍♂️",
            top10Surfers: "Wer sind die Top 10 Surfer, denen man auf den Kanarischen Inseln folgen sollte? 🏆",
            bestSurfSpots: "Was sind die besten Surfspots auf den Kanarischen Inseln? 🌊",
            purposeRayco: "Was ist dein Zweck, Rayco? 🔮",
            send: "Senden",
            restartConversation: "Gespräch neu starten 🔄",
            loadingMessage: "Nachricht wird geladen..."
        },
        nl: {
            letsGoSurfing: "Laten we gaan surfen?",
            forecast6h: "Geef me een 6-uurs voorspelling voor Las Americas en Bajamar 🏄‍♂️",
            top10Surfers: "Wie zijn de top 10 surfers om te volgen op de Canarische Eilanden? 🏆",
            bestSurfSpots: "Wat zijn de beste surfplekken op de Canarische Eilanden? 🌊",
            purposeRayco: "Wat is jouw doel, Rayco? 🔮",
            send: "Versturen",
            restartConversation: "Gesprek herstarten 🔄",
            loadingMessage: "Bericht laden..."
        },
        pt: {
            letsGoSurfing: "Vamos surfar?",
            forecast6h: "Me dê uma previsão de 6 horas para Las Americas e Bajamar 🏄‍♂️",
            top10Surfers: "Quem são os 10 melhores surfistas para seguir nas Ilhas Canárias? 🏆",
            bestSurfSpots: "Quais são os melhores pontos de surf nas Ilhas Canárias? 🌊",
            purposeRayco: "Qual é o seu propósito, Rayco? 🔮",
            send: "Enviar",
            restartConversation: "Reiniciar conversa 🔄",
            loadingMessage: "Carregando mensagem..."
        },
        it: {
            letsGoSurfing: "Andiamo a fare surf?",
            forecast6h: "Dammi una previsione di 6 ore per Las Americas e Bajamar 🏄‍♂️",
            top10Surfers: "Chi sono i migliori 10 surfisti da seguire nelle Isole Canarie? 🏆",
            bestSurfSpots: "Quali sono i migliori spot per surfare nelle Isole Canarie? 🌊",
            purposeRayco: "Qual è il tuo scopo, Rayco? 🔮",
            send: "Invia",
            restartConversation: "Riavvia conversazione 🔄",
            loadingMessage: "Caricamento messaggio..."
        }  
    };

    const messagesRef = useRef(null);
    const sendButtonRef = useRef();

    useEffect(() => {
        if (isChatActive && messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [chatMessages, isChatActive]);
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo(0, 0);
        };
        if (isChatActive) {
            scrollToTop();
        }
    }, [isChatActive]);

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          sendButtonRef.current.click();
        }
    };
    const sendPreWrittenMessage = (content) => {
        sendMessage(content);
    };
    const addMessageToChat = (content, isRaycoMessage, audioUrl, immediate = false) => {
        let messageContent = content;
    
        if (audioUrl && immediate) {
            setChatMessages(prevMessages => [
                ...prevMessages,
                {
                    content,
                    isRaycoMessage,
                    audioMessage: {
                        audioUrl,
                        isPlaying: false
                    }
                }
            ]);
        } else {
            setChatMessages((prevMessages) => [
                ...prevMessages,
                {
                    content: messageContent,
                    isRaycoMessage,
                },
            ]);
        }
    };
    const handleRecordingComplete = (audioFile, formattedDuration) => {

        const previewPlayer = new Audio(URL.createObjectURL(audioFile));

        if (audioFile instanceof Blob) {
            setRecordingStatus({
                isRecording: false,
                duration: formattedDuration,
                audioFile: audioFile,
                previewPlayer: previewPlayer
            });
        }
    };
    const handleStartRecording = () => {

        setRecordingStatus({
          isRecording: true,
          duration: 0,
          audioFile: null,
        });
    };
    const handleRestartConversation = () => {
        setChatMessages([]);
        setIsChatActive(false);
        createNewThread();
    };
    const sendMessage = (messageToSend = message) => {

        setShowLoader(true);

        const actualMessage = messageToSend instanceof Event ? message : messageToSend;
    
        const isAudioMessage = recordingStatus.audioFile != null;
        const content = isAudioMessage ? "" : actualMessage;
    
        if (actualMessage === '' && !recordingStatus.audioFile) {
            console.log("message empty!")
            return;
        }
    
        setIsChatActive(true);
    
        const userMessage = {
            content: content,
            isRaycoMessage: false,
            audioUrl: recordingStatus.audioFile ? URL.createObjectURL(recordingStatus.audioFile) : null,
        };
    
        addMessageToChat(userMessage.content, userMessage.isRaycoMessage, userMessage.audioUrl, true);
    
        const formData = new FormData();
        formData.append('message', content);
        formData.append('threadId', threadId);
    
        if (recordingStatus.audioFile) {
            formData.append('audio', recordingStatus.audioFile);
        }
    
        setRecordingStatus({
            isRecording: false,
            duration: null,
            audioFile: null,
            previewPlayer: null
        });
    
        fetch(`${API_URL}/v1/send-message`, {

            method: 'POST',
            body: formData
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to send message');
            }
        })
        .then((data) => {
    
            console.log(data.text_response)
            const newMessage = {
                content: data.text_response,
                audioMessage: {
                    audioUrl: data.audio_url,
                    isPlaying: false
                },
                isRaycoMessage: true,
            };
            
            setChatMessages(prevMessages => prevMessages.filter(msg => !msg.isLoading).concat(newMessage));setShowLoader(false); // Hide loader after successful response
        })
        .catch((error) => {
            console.error('Error:', error);
            setShowLoader(false); // Hide loader also in case of error
        });
        setMessage('');
    };

    return (
        <div className='h-full'>
            {!isChatActive && (
                <div className='flex flex-col'>
                    <div className='flex justify-center mb-4'>   
                        <img className="rounded-full w-32 lg:w-56 mb-3 lg:mb-10" src="https://res.cloudinary.com/ddl8qwaac/image/upload/v1701107686/Rayco/rayco_pictures/r5em4vqaedsycim9tuhu.jpg" alt="Rayco" />
                    </div>
                    <h1 className="text-3xl lg:text-5xl font-extrabold mb-8">{translations[preferences.language].letsGoSurfing}</h1>
                    <div className="columns-2 lg:columns-4 mb-3 lg:mb-4 gap-3 lg:gap-4">
                        {Object.keys(translations[preferences.language]).slice(1, 5).map((key, index) => (
                            <div className="formOption p-4 mb-3 lg:mb-4" key={index} onClick={() => sendPreWrittenMessage(translations[preferences.language][key])}>
                                {translations[preferences.language][key]}
                            </div>
                        ))}
                    </div>

                    {/* Chat Input Section */}
                    <div className='input'>
                        <ChatInput
                            recordingStatus={recordingStatus}
                            message={message}
                            handleInputChange={handleInputChange}
                            handleKeyPress={handleKeyPress}
                            handleStartRecording={handleStartRecording}
                        />
                        <AudioInput
                            onStartRecording={handleStartRecording}
                            onRecordingComplete={handleRecordingComplete}
                        />
                        <button className='primary-button s send-button' onClick={() => sendMessage()} ref={sendButtonRef}>
                            {translations[preferences.language].send}
                        </button>
                    </div>
                </div>
            )}
            {isChatActive && (
                <div className='flex flex-col text-sm md:text-base pb-10 max-h-screen chatContainer'>
                    {/* Chat Active Layout */}
                    <div className='flex justify-center pfpChatContainer'>   
                        <img className="rounded-full w-20 lg:w-32 pfpChat" src="https://res.cloudinary.com/ddl8qwaac/image/upload/v1701107686/Rayco/rayco_pictures/r5em4vqaedsycim9tuhu.jpg" alt="Rayco" />
                    </div>
                    <div className='flex flex-col bg-black/5 p-3 lg:p-10 chatBox'>
                        {/* <div className="overlay"></div> */}
                        <div className='messages pt-16' ref={messagesRef}>
                            {chatMessages.map((chatMessage, index) => (
                                <Message key={index} chatMessage={chatMessage} />
                            ))}
                        </div>
                        {showLoader && <Loader language={preferences.language} />} {/* Modify text as needed */}
                        <div className='input'>
                            <ChatInput
                                recordingStatus={recordingStatus}
                                message={message}
                                handleInputChange={handleInputChange}
                                handleKeyPress={handleKeyPress}
                                handleStartRecording={handleStartRecording}
                            />
                            <AudioInput
                                onStartRecording={handleStartRecording}
                                onRecordingComplete={handleRecordingComplete}
                            />
                            <button className='primary-button s send-button' onClick={() => sendMessage()} ref={sendButtonRef}>
                                {translations[preferences.language].send}
                            </button>
                        </div>
                    </div>
                    {/* Restart conversation link */}
                    <div className="restart-conversation">
                        <span className="smallLink" onClick={handleRestartConversation}>{translations[preferences.language].restartConversation}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Chat;