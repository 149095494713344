import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Waveform from './Waveform';

const Message = ({ chatMessage }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (chatMessage.isRaycoMessage && chatMessage.audioMessage?.audioUrl && audioRef.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current.src = `${chatMessage.audioMessage.audioUrl}?t=${new Date().getTime()}`;

      audioRef.current.play();
      setIsPlaying(true);
    }

    const handleAudioEnd = () => {
      setIsPlaying(false);
    };

    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener('ended', handleAudioEnd);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handleAudioEnd);
      }
    };
  }, [chatMessage.audioMessage?.audioUrl, chatMessage.isRaycoMessage]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const isHTMLContent = (content) => {
    return /<\/?[a-z][\s\S]*>/i.test(content);
  };

  return (
    <div className={`message ${chatMessage.isRaycoMessage ? 'rayco' : 'user'}`}>

      {/* HTML or Text/Markdown Message */}
      {chatMessage.content && (
        isHTMLContent(chatMessage.content)
          ? <div dangerouslySetInnerHTML={{ __html: chatMessage.content }} />
          : (typeof chatMessage.content === 'string' && <ReactMarkdown children={chatMessage.content} />)
      )}

      {/* Audio Message */}
      {chatMessage.audioMessage?.audioUrl && (
        <div className='audioMessage'>
          <div className='playPauseButton'>
            <audio ref={audioRef} src={chatMessage.audioMessage.audioUrl}></audio>
            <label onClick={togglePlayPause} className='file-input-label'>
              {isPlaying ? '||' : '▶️'}
            </label>
          </div>
          <Waveform isPlaying={isPlaying} audioRef={audioRef} />
        </div>
      )}
    </div>
  );
};

export default Message;
