import React, {useState, useEffect} from 'react';

const Loader = ({ language }) => {
    const [currentLoaderIndex, setCurrentLoaderIndex] = useState(0);

    const translations = {
        en: {
            paddlingOut: "🏊‍♂️ Paddling out with your message...",
            waitingForSet: "👀 Waiting for a set besides <a href='https://www.instagram.com/luisdiazur/' target='_blank' style='color: var(--cta1);'>@luisdiazur</a>...",
            takingAdvice: "🤙 Taking advice from the best surf instructor <a href='https://www.instagram.com/danisacosta98/' target='_blank' style='color: var(--cta1);'>@danisacosta98</a>...",
            bigSetComing: "😲 Here comes a big set!!",
            takingOff: "🏄‍♂️ Taking off...",
            droppingOn: "🌊 Dropping on <a href='https://www.instagram.com/moisesdominguezz/' target='_blank' style='color: var(--cta1);'>@moisesdominguezz</a> (Sorryyy!!)...",
            noseRiding: "🏄🏽‍♀️ Nose riding along <a href='https://www.instagram.com/lucia.machado_/' target='_blank' style='color: var(--cta1);'>@lucia.machado_</a>...",
            takingShots: "📸 Taking nice shots for the <a href='https://www.instagram.com/canariassurffilmfestival/' target='_blank' style='color: var(--cta1);'>@canariassurffilmfestival</a>...",
            registeringFor: "🎫 Registering for <a href='https://www.instagram.com/lasamericasurfpro/' target='_blank' style='color: var(--cta1);'>@lasamericasurfpro</a> and <a href='https://www.instagram.com/quemao_class/' target='_blank' style='color: var(--cta1);'>@quemao_class</a>...",
            backOnBeach: "🏖️ Back on the beach...",
            askingWhyLong: "👨‍🔧 Asking <a href='https://www.instagram.com/titi_build/' target='_blank' style='color: var(--cta1);'>@titi_build</a> why it's taking so long..."
          },
        es: {
            paddlingOut: "🏊‍♂️ Remando con tu mensaje...",
            waitingForSet: "👀 Esperando a una serie con <a href='https://www.instagram.com/luisdiazur/' target='_blank' style='color: var(--cta1);'>@luisdiazur</a>...",
            takingAdvice: "🤙 Tomando consejo del mejor instructor de surf <a href='https://www.instagram.com/danisacosta98/' target='_blank' style='color: var(--cta1);'>@danisacosta98</a>...",
            bigSetComing: "😲 ¡¡Aquí viene una serie grande!!",
            takingOff: "🏄‍♂️ ¡Despegando!...",
            droppingOn: "🌊 ¡¡Cogiendo la ola antes de <a href='https://www.instagram.com/moisesdominguezz/' target='_blank' style='color: var(--cta1);'>@moisesdominguezz</a> (¡¡Perdón!!)...",
            noseRiding: "🏄🏽‍♀️ Nose-riding al lado de <a href='https://www.instagram.com/lucia.machado_/' target='_blank' style='color: var(--cta1);'>@lucia.machado_</a>...",
            takingShots: "📸 Tomando fotos para el <a href='https://www.instagram.com/canariassurffilmfestival/' target='_blank' style='color: var(--cta1);'>@canariassurffilmfestival</a>...",
            registeringFor: "🎫 Registrándome para <a href='https://www.instagram.com/lasamericasurfpro/' target='_blank' style='color: var(--cta1);'>@lasamericasurfpro</a> y <a href='https://www.instagram.com/quemao_class/' target='_blank' style='color: var(--cta1);'>@quemao_class</a>...",
            backOnBeach: "🏖️ De vuelta en la playa...",
            askingWhyLong: "👨‍🔧 Preguntando a <a href='https://www.instagram.com/titi_build/' target='_blank' style='color: var(--cta1);'>@titi_build</a> por qué está tardando tanto..."
        },
        fr:{
            paddlingOut: "🏊‍♂️ A la nage avec ton message...",
            waitingForSet: "👀 En attente d'un set aux côtés de <a href='https://www.instagram.com/luisdiazur/' target='_blank' style='color: var(--cta1);'>@luisdiazur</a>...",
            takingAdvice: "🤙 Quelques conseils du meilleur instructeur de surf <a href='https://www.instagram.com/danisacosta98/' target='_blank' style='color: var(--cta1);'>@danisacosta98</a>...",
            bigSetComing: "😲 Voici un gros set!!",
            takingOff: "🏄‍♂️ Take off...",
            droppingOn: "🌊 Drop sur <a href='https://www.instagram.com/moisesdominguezz/' target='_blank' style='color: var(--cta1);'>@moisesdominguezz</a> (Désolé!!)...",
            noseRiding: "🏄🏽‍♀️ Nose-ride aux côtés de <a href='https://www.instagram.com/lucia.machado_/' target='_blank' style='color: var(--cta1);'>@lucia.machado_</a>...",
            takingShots: "📸 On prend quelques photos pour le <a href='https://www.instagram.com/canariassurffilmfestival/' target='_blank' style='color: var(--cta1);'>@canariassurffilmfestival</a>...",
            registeringFor: "🎫 On s'inscrit au <a href='https://www.instagram.com/lasamericasurfpro/' target='_blank' style='color: var(--cta1);'>@lasamericasurfpro</a> et <a href='https://www.instagram.com/quemao_class/' target='_blank' style='color: var(--cta1);'>@quemao_class</a>...",
            backOnBeach: "🏖️ De retour sur la plage...",
            askingWhyLong: "👨‍🔧 On demande à <a href='https://www.instagram.com/titi_build/' target='_blank' style='color: var(--cta1);'>@titi_build</a> pourquoi ça prend autant de temps..."
        },
        de: {
            paddlingOut: "🏊‍♂️ Paddle mit deiner Nachricht raus...",
            waitingForSet: "👀 Warte auf eine Welle mit <a href='https://www.instagram.com/luisdiazur/' target='_blank' style='color: var(--cta1);'>@luisdiazur</a>...",
            takingAdvice: "🤙 Hole mir Ratschläge vom besten Surflehrer <a href='https://www.instagram.com/danisacosta98/' target='_blank' style='color: var(--cta1);'>@danisacosta98</a>...",
            bigSetComing: "😲 Da kommt eine große Welle!!",
            takingOff: "🏄‍♂️ Ab geht's...",
            droppingOn: "🌊 Gleite ab auf <a href='https://www.instagram.com/moisesdominguezz/' target='_blank' style='color: var(--cta1);'>@moisesdominguezz</a> (Entschuldigung!!)...",
            noseRiding: "🏄🏽‍♀️ Nose Riding zusammen mit <a href='https://www.instagram.com/lucia.machado_/' target='_blank' style='color: var(--cta1);'>@lucia.machado_</a>...",
            takingShots: "📸 Mache tolle Aufnahmen für <a href='https://www.instagram.com/canariassurffilmfestival/' target='_blank' style='color: var(--cta1);'>@canariassurffilmfestival</a>...",
            registeringFor: "🎫 Melde mich an für <a href='https://www.instagram.com/lasamericasurfpro/' target='_blank' style='color: var(--cta1);'>@lasamericasurfpro</a> und <a href='https://www.instagram.com/quemao_class/' target='_blank' style='color: var(--cta1);'>@quemao_class</a>...",
            backOnBeach: "🏖️ Zurück am Strand...",
            askingWhyLong: "👨‍🔧 Frage <a href='https://www.instagram.com/titi_build/' target='_blank' style='color: var(--cta1);'>@titi_build</a>, warum es so lange dauert..."
        },
        it: {
            paddlingOut: "🏊‍♂️ Remando fuori con il tuo messaggio...",
            waitingForSet: "👀 In attesa di un'onda con <a href='https://www.instagram.com/luisdiazur/' target='_blank' style='color: var(--cta1);'>@luisdiazur</a>...",
            takingAdvice: "🤙 Prendendo consigli dal miglior istruttore di surf <a href='https://www.instagram.com/danisacosta98/' target='_blank' style='color: var(--cta1);'>@danisacosta98</a>...",
            bigSetComing: "😲 Ecco arrivare un'onda grande!!",
            takingOff: "🏄‍♂️ Si parte...",
            droppingOn: "🌊 Lanciandosi su <a href='https://www.instagram.com/moisesdominguezz/' target='_blank' style='color: var(--cta1);'>@moisesdominguezz</a> (Scusate!!)...",
            noseRiding: "🏄🏽‍♀️ Nose riding insieme a <a href='https://www.instagram.com/lucia.machado_/' target='_blank' style='color: var(--cta1);'>@lucia.machado_</a>...",
            takingShots: "📸 Scattando belle foto per <a href='https://www.instagram.com/canariassurffilmfestival/' target='_blank' style='color: var(--cta1);'>@canariassurffilmfestival</a>...",
            registeringFor: "🎫 Registrandosi per <a href='https://www.instagram.com/lasamericasurfpro/' target='_blank' style='color: var(--cta1);'>@lasamericasurfpro</a> e <a href='https://www.instagram.com/quemao_class/' target='_blank' style='color: var(--cta1);'>@quemao_class</a>...",
            backOnBeach: "🏖️ Tornato sulla spiaggia...",
            askingWhyLong: "👨‍🔧 Chiedendo a <a href='https://www.instagram.com/titi_build/' target='_blank' style='color: var(--cta1);'>@titi_build</a> perché ci vuole così tanto tempo..."
        },
        nl: {
            paddlingOut: "🏊‍♂️ Peddel uit met je bericht...",
            waitingForSet: "👀 Wachten op een golfset mit <a href='https://www.instagram.com/luisdiazur/' target='_blank' style='color: var(--cta1);'>@luisdiazur</a>...",
            takingAdvice: "🤙 Advies inwinnen bij de beste surfinstructeur <a href='https://www.instagram.com/danisacosta98/' target='_blank' style='color: var(--cta1);'>@danisacosta98</a>...",
            bigSetComing: "😲 Daar komt een grote set!!",
            takingOff: "🏄‍♂️ We gaan ervoor...",
            droppingOn: "🌊 Droppen op <a href='https://www.instagram.com/moisesdominguezz/' target='_blank' style='color: var(--cta1);'>@moisesdominguezz</a> (Sorry!!)...",
            noseRiding: "🏄🏽‍♀️ Nose riding samen met <a href='https://www.instagram.com/lucia.machado_/' target='_blank' style='color: var(--cta1);'>@lucia.machado_</a>...",
            takingShots: "📸 Mooie foto's maken voor <a href='https://www.instagram.com/canariassurffilmfestival/' target='_blank' style='color: var(--cta1);'>@canariassurffilmfestival</a>...",
            registeringFor: "🎫 Aanmelden voor <a href='https://www.instagram.com/lasamericasurfpro/' target='_blank' style='color: var(--cta1);'>@lasamericasurfpro</a> en <a href='https://www.instagram.com/quemao_class/' target='_blank' style='color: var(--cta1);'>@quemao_class</a>...",
            backOnBeach: "🏖️ Terug op het strand...",
            askingWhyLong: "👨‍🔧 Vragen <a href='https://www.instagram.com/titi_build/' target='_blank' style='color: var(--cta1);'>@titi_build</a> waarom het zo lang duurt..."
        },
        pt: {
            paddlingOut: "🏊‍♂️ Remando com a sua mensagem...",
            waitingForSet: "👀 Esperando por uma série de onda com <a href='https://www.instagram.com/luisdiazur/' target='_blank' style='color: var(--cta1);'>@luisdiazur</a>...",
            takingAdvice: "🤙 Pegando conselhos com o melhor instrutor de surf <a href='https://www.instagram.com/danisacosta98/' target='_blank' style='color: var(--cta1);'>@danisacosta98</a>...",
            bigSetComing: "😲 Lá vem uma série grande!!",
            takingOff: "🏄‍♂️ Partindo...",
            droppingOn: "🌊 Descendo em <a href='https://www.instagram.com/moisesdominguezz/' target='_blank' style='color: var(--cta1);'>@moisesdominguezz</a> (Desculpe!!)...",
            noseRiding: "🏄🏽‍♀️ Fazendo nose riding com <a href='https://www.instagram.com/lucia.machado_/' target='_blank' style='color: var(--cta1);'>@lucia.machado_</a>...",
            takingShots: "📸 Tirando fotos legais para <a href='https://www.instagram.com/canariassurffilmfestival/' target='_blank' style='color: var(--cta1);'>@canariassurffilmfestival</a>...",
            registeringFor: "🎫 Registrando-se para <a href='https://www.instagram.com/lasamericasurfpro/' target='_blank' style='color: var(--cta1);'>@lasamericasurfpro</a> e <a href='https://www.instagram.com/quemao_class/' target='_blank' style='color: var(--cta1);'>@quemao_class</a>...",
            backOnBeach: "🏖️ De volta à praia...",
            askingWhyLong: "👨‍🔧 Perguntando a <a href='https://www.instagram.com/titi_build/' target='_blank' style='color: var(--cta1);'>@titi_build</a> por que está demorando tanto..."
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentLoaderIndex(prevIndex => (prevIndex + 1) % Object.keys(translations[language]).length);
        }, 4000);
    
        return () => clearInterval(intervalId);
    }, [language]);
    

    const getLoaderMessage = () => {
        const messages = Object.values(translations[language]);
        return messages[currentLoaderIndex];
    };

    return (
        <div className="loader text-xs my-4 bg-dark3 w-fit p-3">
            <div className="text-left" dangerouslySetInnerHTML={{ __html: getLoaderMessage() }} />
            <div className="loader-animation">|</div>
        </div>
    );
};

export default Loader;
