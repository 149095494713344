import React, { useState, useEffect, useRef } from 'react';
import Onboarding from '../components/Onboarding';
/* import Gradient from '../components/Gradient';
 */
import Chat from '../components/Chat';
import Forecast from '../components/Forecast';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.rayco.surf';

const Home = () => {

    const [onboardingComplete, setOnboardingComplete] = useState(false);
    const [threadId, setThreadId] = useState(null);

    useEffect(() => {
        createNewThread();
    }, []);

    console.log(`API URL is: ${API_URL}`);
    const createNewThread = () => {
        fetch(`${API_URL}/v1/create-thread`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to create a thread.');
            }
        })
        .then((data) => {
            setThreadId(data.threadId);
        })
        .catch((error) => {
            console.error(`Error:`, error);
        });
    };
    const handleOnboardingStart = () => {
        setOnboardingComplete(true);
    }; 

    return (
        <>
            <div className="container mx-auto px-4 pt-8 pb-4 lg:p-10 text-clear1 font-medium w-full md:w-3/4 lg:w-3/5 text-sm lg:text-base h-screen">
                {/* <Forecast /> */}
                {!onboardingComplete ? (
                    <Onboarding onStart={handleOnboardingStart} />
                ) : (
                    <Chat createNewThread={createNewThread} threadId={threadId} />
                )}
            </div>
            {/* <Gradient /> */}
        </>
    );
        
};

export default Home;