// ExternalInput.js
import React, {useEffect, useState} from 'react';
import { useUserPreferences } from '../contexts/UserPreferencesContext';

const ChatInput = ({
  recordingStatus,
  message,
  handleInputChange,
  handleKeyPress,
  handleStartRecording,
}) => {
  
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);
  const {preferences} = useUserPreferences();
  const translations = {
    en: {
      recordingAudio:"Recording audio...",
      audioMessage:"Audio message:",
      typeMessage:"Type a message...",
    },
    es: {
      recordingAudio:"Grabando audio...",
      audioMessage:"Mensaje audio:",
      typeMessage:"Escribe un mensaje...",
    },
    fr:{
      recordingAudio:"Enregistrement audio...",
      audioMessage:"Message audio:",
      typeMessage:"Tape un message...",
    },
    de: {
      recordingAudio:"Audioaufnahme...",
      audioMessage:"Audio Nachricht:",
      typeMessage:"Schreibe eine Nachricht...",
    },
    it: {
      recordingAudio:"Registrazione audio...",
      audioMessage:"Messaggio audio:",
      typeMessage:"Scrivi un messaggio...",
    },
    nl: {
      recordingAudio:"Audio opname...",
      audioMessage:"Audio bericht:",
      typeMessage:"Typ een bericht...",
    },
    pt: {
      recordingAudio:"Gravação de áudio...",
      audioMessage:"Mensagem de áudio:",
      typeMessage:"Digite uma mensagem...",
    },
  }

  useEffect(() => {
    const player = recordingStatus.previewPlayer;

    const handleAudioEnd = () => {
        setIsPreviewPlaying(false);
    };

    if (player) {
        player.addEventListener('ended', handleAudioEnd);
    }

    return () => {
        if (player) {
            player.removeEventListener('ended', handleAudioEnd);
        }
    };
}, [recordingStatus.previewPlayer]);

  if (recordingStatus.isRecording) {
    return (
      <div className='audioMessageInput'>
        <p className='text-left'>{translations[preferences.language].recordingAudio}</p>
      </div>
    )
  } else if (recordingStatus.audioFile) {
    return (
      <div className='audioMessageInput'>
        <p className='text-left'>{translations[preferences.language].audioMessage} {recordingStatus.duration}</p>
        <div className='playPauseButton'>
        {isPreviewPlaying ? (
          <label
            onClick={() => {
              recordingStatus.previewPlayer.pause();
              setIsPreviewPlaying(false);
            }}
            className='file-input-label'
          >
            ||
          </label>
        ) : (
          <label
            onClick={() => {
              recordingStatus.previewPlayer.play();
              setIsPreviewPlaying(true);
            }}
            className='file-input-label'
          >
            ▶️
          </label>
        )}
      </div>

      </div>
    );
  } else {
    return (
      <textarea
        placeholder={translations[preferences.language].typeMessage}
        value={message}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
    );
  }
};

export default ChatInput;
