import React, { useState, useEffect } from 'react';
import { useAnalytics } from '../contexts/AnalyticsContext';
import { useUserPreferences } from '../contexts/UserPreferencesContext';

const Onboarding = ({ onStart }) => {
    const analytics = useAnalytics();

    const [currentStep, setCurrentStep] = useState(1);

    const translations = {
        en: {
            greeting: "Hey bro 🤙",
            greeting2:"Let's prepare your next session!",
            surferType: "Surfer Type",
            beginner: "First sessions",
            casual: "Casual",
            regular: "Regular",
            competitive: "Competitive",
            yourGoals: "Your Goals",
            findBestServices: "Find the best services (rent or buy, courses,...)",
            personalizedForecast: "Get a personalized surf forecast",
            learnEcosystem: "Learn about Canarian surf ecosystem (events, people, spots...)",
            personalizedGuidance: "Get personalized guidance on your surf practice (training, nutrition...)",
            start: "Let's go!"
        },
        es: {
            greeting: "Hola bro 🤙",
            greeting2:"Preparemos tu próxima sesión!",
            surferType: "Tipo de surfista",
            beginner: "Principiante",
            casual: "Casual",
            regular: "Regular",
            competitive: "Competitivo",
            yourGoals: "Tus objetivos",
            findBestServices: "Encuentra los mejores servicios (alquiler o compra, cursos,...)",
            personalizedForecast: "Obtén un pronóstico de surf personalizado",
            learnEcosystem: "Aprende sobre el ecosistema del surf canario (eventos, personas, lugares...)",
            personalizedGuidance: "Recibe orientación personalizada en tu práctica de surf (entrenamiento, nutrición...)",
            start: "Vamos!"
        },
        fr: {
            greeting: "Hey bro 🤙",
            greeting2:"On prépare ta prochaine session?",
            surferType: "Type de surfeur",
            beginner: "Débutant",
            casual: "Décontracté",
            regular: "Régulier",
            competitive: "Compétitif",
            yourGoals: "Tes objectifs",
            findBestServices: "Trouve les meilleurs services (location, achat, cours,...)",
            personalizedForecast: "Prévisions de surf personnalisées",
            learnEcosystem: "Infos sur l'écosystème du surf canarien (événements, personnes, spots...)",
            personalizedGuidance: "Conseils personnalisés sur ta pratique du surf (entraînement, nutrition...)",
            start: "C'est parti"
        },
        de: {
            greeting: "Hey Bruder 🤙",
            greeting2:"Lass uns deine nächste Session vorbereiten!",
            surferType: "Surfertyp",
            beginner: "Anfänger",
            casual: "Gelegenheits-Surfer",
            regular: "Regelmäßig",
            competitive: "Wettbewerbsfähig",
            yourGoals: "Deine Ziele",
            findBestServices: "Finde die besten Dienstleistungen (mieten oder kaufen, Kurse,...)",
            personalizedForecast: "Erhalte eine personalisierte Surf-Vorhersage",
            learnEcosystem: "Erfahre mehr über das kanarische Surf-Ökosystem (Veranstaltungen, Personen, Spots...)",
            personalizedGuidance: "Erhalte persönliche Anleitung für deine Surfpraxis (Training, Ernährung...)",
            start: "Los geht's!"
        },
        it: {
            greeting: "Ciao frate 🤙",
            greeting2:"Prepariamo la tua prossima sessione!",
            surferType: "Tipo di surfista",
            beginner: "Principiante",
            casual: "Casual",
            regular: "Regolare",
            competitive: "Competitivo",
            yourGoals: "I tuoi obiettivi",
            findBestServices: "Trova i migliori servizi (noleggio o acquisto, corsi,...)",
            personalizedForecast: "Ottieni previsioni del surf personalizzate",
            learnEcosystem: "Scopri l'ecosistema del surf delle Canarie (eventi, persone, luoghi...)",
            personalizedGuidance: "Ottieni una guida personalizzata sulla tua pratica del surf (allenamento, nutrizione...)",
            start: "Andiamo!"
        },
        nl: {
            greeting: "Hey bro 🤙",
            greeting2:"Laten we je volgende sessie voorbereiden!",
            surferType: "Surfertype",
            beginner: "Beginner",
            casual: "Casual",
            regular: "Regelmatig",
            competitive: "Competitief",
            yourGoals: "Jouw doelen",
            findBestServices: "Vind de beste diensten (huren of kopen, cursussen,...)",
            personalizedForecast: "Ontvang een gepersonaliseerde surfvoorspelling",
            learnEcosystem: "Leer over het surfecosysteem van de Canarische Eilanden (evenementen, mensen, plekken...)",
            personalizedGuidance: "Ontvang gepersonaliseerde begeleiding voor je surfpraktijk (training, voeding...)",
            start: "Laten we gaan!"
        },
        pt: {
            greeting: "E aí, irmão 🤙",
            greeting2:"Vamos preparar a tua próxima sessão!",
            surferType: "Tipo de Surfista",
            beginner: "Iniciante",
            casual: "Casual",
            regular: "Regular",
            competitive: "Competitivo",
            yourGoals: "Seus Objetivos",
            findBestServices: "Encontre os melhores serviços (aluguel ou compra, cursos,...)",
            personalizedForecast: "Obtenha uma previsão de surf personalizada",
            learnEcosystem: "Conheça o ecossistema de surf das Canárias (eventos, pessoas, lugares...)",
            personalizedGuidance: "Receba orientação personalizada na sua prática de surf (treinamento, nutrição...)",
            start: "Vamos!"
        },
        
    };
    const languageMapping = {
        spanish: 'es',
        english: 'en',
        french: 'fr',
        german: 'de',
        italian: 'it',
        dutch: 'nl',
        portuguese: 'pt'
    };

    const [preferences, setPreferences] = useState({
        language: '',
        surferType: '',
        userGoals: []
    });
    const browserLanguage = navigator.language.split('-')[0];

    useEffect(() => {
        analytics.track('OnboardingStep', { onboardingStep: "1" });
    }, [analytics]);

    const { updatePreferences } = useUserPreferences(); // Use the hook

    const handleLanguageSelect = (language) => {
        const languageCode = languageMapping[language];
        setPreferences(prevState => ({
            ...prevState,
            language: languageCode
        }));
        updatePreferences({ language: languageCode });
        setCurrentStep(2);
        analytics.track('OnboardingStep', { onboardingStep: "2" });
    };
    

    const selectedLanguage = preferences.language || browserLanguage;
    const languageKey = translations[selectedLanguage] ? selectedLanguage : 'en';
    const t = translations[languageKey];

    const handleSurferTypeSelect = (type) => {
        updatePreferences({ surferType: type }); // Update preferences
        setCurrentStep(3);
        analytics.track('OnboardingStep', { onboardingStep: "3" });
    };

    const handleGoalToggle = (event) => {
        const goal = event.target.value;
        setPreferences(prevState => {
            const userGoals = prevState.userGoals.includes(goal) ?
                prevState.userGoals.filter(g => g !== goal) :
                [...prevState.userGoals, goal];
    
            // Defer the updatePreferences call to the next event loop cycle
            setTimeout(() => {
                updatePreferences({ userGoals });
            }, 0);
    
            return { ...prevState, userGoals };
        });
    };

    const startChat = () => {
        analytics.track('OnboardingData', preferences);
        analytics.track('OnboardingStep', { onboardingStep: "Complete" });
        onStart(preferences); 
        setCurrentStep(1);
    };
  
  return (
    <div className="onboarding">
        <div className='mb-4'>   
            <img className="rounded-full w-40 lg:w-56 mb-5 lg:mb-10" src="https://res.cloudinary.com/ddl8qwaac/image/upload/v1701107686/Rayco/rayco_pictures/r5em4vqaedsycim9tuhu.jpg" alt="Rayco" />
        </div>
        <h1 className='text-4xl lg:text-5xl font-extrabold mb-10'>{t.greeting}</h1>

        {currentStep === 1 && (
            <div className="w-full">
                <p className='text-xl font-semibold mb-8'>{t.greeting2}</p>
                <div className="language-flags">
                    <div className="columns-2 w-full text-4xl mb-3 lg:mb-4 gap-3 lg:gap-4">
                        <div className="formOption py-5" onClick={() => handleLanguageSelect('spanish')}>🇪🇸</div>
                        <div className="formOption py-5" onClick={() => handleLanguageSelect('english')}>🇬🇧</div>
                    </div>
                    <div className="columns-5 w-full text-xl mb-3 lg:mb-4 gap-3 lg:gap-4">
                        <div className="formOption py-3" onClick={() => handleLanguageSelect('french')}>🇫🇷</div>
                        <div className="formOption py-3" onClick={() => handleLanguageSelect('german')}>🇩🇪</div>
                        <div className="formOption py-3" onClick={() => handleLanguageSelect('italian')}>🇮🇹</div>
                        <div className="formOption py-3" onClick={() => handleLanguageSelect('dutch')}>🇳🇱</div>
                        <div className="formOption py-3" onClick={() => handleLanguageSelect('portuguese')}>🇵🇹</div>
                    </div>
                </div>
            </div>
        )}

        {currentStep === 2 && (
            <div className="w-full">
                <h2 className='text-xl font-bold mb-5'>{t.surferType}</h2>
                <div className="columns-2 xl:columns-4 gap-3 lg:gap-4">
                    <div onClick={() => handleSurferTypeSelect('beginner')} className="formOption p-4 mb-3 lg:mb-4">
                        <span className="mr-2">🩳</span>
                        <label>{t.beginner}</label>
                    </div>
                    <div onClick={() => handleSurferTypeSelect('casual')} className="formOption p-4 mb-3 lg:mb-4">
                        <span className="mr-2">🏄‍♂️</span>
                        <label>{t.casual}</label>
                    </div>
                    <div onClick={() => handleSurferTypeSelect('regular')} className="formOption p-4 mb-3 lg:mb-4">
                        <span className="mr-2">💪</span>
                        <label>{t.regular}</label>
                    </div>
                    <div onClick={() => handleSurferTypeSelect('competitive')} className="formOption p-4 mb-3 lg:mb-4">
                        <span className="mr-2">🏆</span>
                        <label>{t.competitive}</label>
                    </div>
                    
                </div>
            </div>  
        )}
        
        {currentStep === 3 && (
            <div className="font-medium w-full">
                <h2 className='text-xl font-bold mb-5'>{t.yourGoals}</h2>
                <div className="user-goals mb-5">
                    <label className='formOption p-4 mb-3 lg:mb-4'>
                        <input 
                            type="checkbox" 
                            id="findBestServices"
                            value="findBestServices"
                            onChange={handleGoalToggle}
                        />
                        <span className="custom-checkbox"></span>
                        <div>{t.findBestServices}</div>
                    </label>
                    <label className='formOption p-4 mb-3 lg:mb-4'>
                        <input 
                            type="checkbox" 
                            id="personalizedForecast"
                            value="personalizedForecast"
                            onChange={handleGoalToggle}
                        />
                        <span className="custom-checkbox"></span>
                        <div>{t.personalizedForecast}</div>
                    </label>
                    <label className='formOption p-4 mb-3 lg:mb-4'>
                        <input 
                            type="checkbox" 
                            id="learnEcosystem"
                            value="learnEcosystem"
                            onChange={handleGoalToggle}
                        />
                        <span className="custom-checkbox"></span>
                        <div>{t.learnEcosystem}</div>
                    </label>
                    <label className='formOption p-4 mb-3 lg:mb-4'>
                        <input 
                            type="checkbox" 
                            id="personalizedGuidance"
                            value="personalizedGuidance"
                            onChange={handleGoalToggle}
                        />
                        <span className="custom-checkbox"></span>
                        <div>{t.personalizedGuidance}</div>
                    </label>
                </div>
                <button 
                    className="primary-button" 
                    onClick={startChat} 
                    disabled={preferences.userGoals.length === 0}
                >
                    {t.start}
                </button>
            </div>
        )}
    </div>
  );
};

export default Onboarding;
