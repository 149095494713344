import React, { useState } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

const Forecast = () => {
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [spots, setSpots] = useState([]);
    const [error, setError] = useState(''); // New state for handling errors

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, handleError);
        } else {
            setLocation({ latitude: "Geolocation is not supported by this browser.", longitude: "" });
        }
    }

    function showPosition(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation({ latitude, longitude });
    }

    function handleError(error) {
        setLocation({ latitude: `Error: ${error.message}`, longitude: '' });
    }

    async function getSpots() {
        try {
            console.log('location', location)
            const response = await fetch(`${API_URL}/v1/get-spots-nearby?latitude=${location.latitude}&longitude=${location.longitude}`, {
                mode: 'no-cors',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            console.log('data', data)
            if (Array.isArray(data)) { 
                setSpots(data);
                setError('');
            } else {
                setError('Invalid data format received from the server');
                setSpots([]);
            }
        } catch (error) {
            setError(`Error: ${error.message}`);
            setSpots([]);
        }
    }

    return (
        <div>
            <h1>Where should I go surfing today?</h1>
            <button className="primary-button" onClick={getLocation}>Get my location</button>
            {location.latitude && location.longitude && (
                <div>
                    <p>Latitude: {location.latitude}</p>
                    <p>Longitude: {location.longitude}</p>
                </div>
            )}
            <button className="primary-button" onClick={getSpots}>Get spots around me</button>
            {spots.map((spot, index) => (
                <div key={index}>
                    <h3>{spot.name}</h3>
                    <p>Latitude: {spot.latitude}</p>
                    <p>Longitude: {spot.longitude}</p>
                    <p>Distance: {spot.distance.toFixed(2)} km</p>
                </div>
            ))}

            {error && <p>Error: {error}</p>} {/* Display the error if there is one */}
        </div>
    );
}

export default Forecast;
