import React, { useEffect, useRef } from 'react';

const Waveform = ({ isPlaying, audioRef }) => {
    const svgRef = useRef(null);

    useEffect(() => {
        const updateWaveform = () => {
            const totalBars = 37;
            const fraction = audioRef.current.currentTime / audioRef.current.duration || 0;
            const barsToColor = Math.floor(totalBars * fraction);
            for (let i = 1; i < totalBars; i++) {
                const path = svgRef.current.querySelector(`[data-index="${i}"]`);
                if (path) {
                    path.style.fill = i < barsToColor ? '#EF8354' : 'white';
                }
            }
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', updateWaveform);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', updateWaveform);
            }
        };
    }, [audioRef]);

    useEffect(() => {
        if (!isPlaying) {
            for (let i = 0; i < 37; i++) {
                const path = svgRef.current.querySelector(`[data-index="${i}"]`);
                if (path) {
                    path.style.fill = 'white';
                }
            }
        }
    }, [isPlaying]);

    return (
        <div className="waveform">
            <svg ref={svgRef} viewBox="0 0 84 28" xmlns="http://www.w3.org/2000/svg">
                <path key={1} data-index="1" d="M1.31891 15.3606C1.07891 15.3606 0.878906 15.1606 0.878906 14.9206V13.0806C0.878906 12.8406 1.07891 12.6406 1.31891 12.6406C1.55891 12.6406 1.75891 12.8406 1.75891 13.0806V14.9206C1.75891 15.1706 1.55891 15.3606 1.31891 15.3606Z" fill="white"/>
                <path key={2} data-index="2" d="M3.60016 15.3606C3.36016 15.3606 3.16016 15.1606 3.16016 14.9206V13.0806C3.16016 12.8406 3.36016 12.6406 3.60016 12.6406C3.84016 12.6406 4.04016 12.8406 4.04016 13.0806V14.9206C4.04016 15.1706 3.84016 15.3606 3.60016 15.3606Z" fill="white"/>
                <path key={3} data-index="3" d="M5.88141 15.3606C5.64141 15.3606 5.44141 15.1606 5.44141 14.9206V13.0806C5.44141 12.8406 5.64141 12.6406 5.88141 12.6406C6.12141 12.6406 6.32141 12.8406 6.32141 13.0806V14.9206C6.32141 15.1706 6.12141 15.3606 5.88141 15.3606Z" fill="white"/>
                <path key={4} data-index="4" d="M8.15875 21.2195C7.91875 21.2195 7.71875 21.0195 7.71875 20.7795V7.20953C7.71875 6.96953 7.91875 6.76953 8.15875 6.76953C8.39875 6.76953 8.59875 6.96953 8.59875 7.20953V20.7795C8.59875 21.0295 8.40875 21.2195 8.15875 21.2195Z" fill="white"/>
                <path key={5} data-index="5" d="M10.4517 15.7992C10.2117 15.7992 10.0117 15.5992 10.0117 15.3592V12.6392C10.0117 12.3992 10.2117 12.1992 10.4517 12.1992C10.6917 12.1992 10.8917 12.3992 10.8917 12.6392V15.3592C10.8917 15.5992 10.6917 15.7992 10.4517 15.7992Z" fill="white"/>
                <path key={6} data-index="6" d="M12.7291 16.8319C12.4891 16.8319 12.2891 16.6319 12.2891 16.3919V11.6119C12.2891 11.3719 12.4891 11.1719 12.7291 11.1719C12.9691 11.1719 13.1691 11.3719 13.1691 11.6119V16.3919C13.1691 16.6319 12.9691 16.8319 12.7291 16.8319Z" fill="white"/>
                <path key={7} data-index="7" d="M15.0103 15.3606C14.7703 15.3606 14.5703 15.1606 14.5703 14.9206V13.0806C14.5703 12.8406 14.7703 12.6406 15.0103 12.6406C15.2503 12.6406 15.4503 12.8406 15.4503 13.0806V14.9206C15.4503 15.1706 15.2503 15.3606 15.0103 15.3606Z" fill="white"/>
                <path key={8} data-index="8" d="M17.2916 15.3606C17.0516 15.3606 16.8516 15.1606 16.8516 14.9206V13.0806C16.8516 12.8406 17.0516 12.6406 17.2916 12.6406C17.5316 12.6406 17.7316 12.8406 17.7316 13.0806V14.9206C17.7316 15.1706 17.5316 15.3606 17.2916 15.3606Z" fill="white"/>
                <path key={9} data-index="9" d="M19.5689 15.3606C19.3289 15.3606 19.1289 15.1606 19.1289 14.9206V13.0806C19.1289 12.8406 19.3289 12.6406 19.5689 12.6406C19.8089 12.6406 20.0089 12.8406 20.0089 13.0806V14.9206C20.0089 15.1706 19.8189 15.3606 19.5689 15.3606Z" fill="white"/>
                <path key={10} data-index="10" d="M21.8619 15.7983C21.6219 15.7983 21.4219 15.5983 21.4219 15.3583V12.9283C21.4219 12.6883 21.6219 12.4883 21.8619 12.4883C22.1019 12.4883 22.3019 12.6883 22.3019 12.9283V15.3583C22.3019 15.5983 22.1019 15.7983 21.8619 15.7983Z" fill="white"/>
                <path key={11} data-index="11" d="M24.1392 17.4089C23.8992 17.4089 23.6992 17.2089 23.6992 16.9689V11.3189C23.6992 11.0789 23.8992 10.8789 24.1392 10.8789C24.3792 10.8789 24.5792 11.0789 24.5792 11.3189V16.9689C24.5792 17.2189 24.3792 17.4089 24.1392 17.4089Z" fill="white"/>
                <path key={12} data-index="12" d="M26.4205 15.7992C26.1805 15.7992 25.9805 15.5992 25.9805 15.3592V12.6392C25.9805 12.3992 26.1805 12.1992 26.4205 12.1992C26.6605 12.1992 26.8605 12.3992 26.8605 12.6392V15.3592C26.8605 15.5992 26.6605 15.7992 26.4205 15.7992Z" fill="white"/>
                <path key={13} data-index="13" d="M28.7017 16.8319C28.4617 16.8319 28.2617 16.6319 28.2617 16.3919V11.6119C28.2617 11.3719 28.4617 11.1719 28.7017 11.1719C28.9417 11.1719 29.1417 11.3719 29.1417 11.6119V16.3919C29.1417 16.6319 28.9417 16.8319 28.7017 16.8319Z" fill="white"/>
                <path key={14} data-index="14" d="M30.9791 19.7583C30.7391 19.7583 30.5391 19.5583 30.5391 19.3183V8.67828C30.5391 8.43828 30.7391 8.23828 30.9791 8.23828C31.2191 8.23828 31.4191 8.43828 31.4191 8.67828V19.3183C31.4191 19.5583 31.2291 19.7583 30.9791 19.7583Z" fill="white"/>
                <path key={15} data-index="15" d="M33.2681 21.2195C33.0281 21.2195 32.8281 21.0195 32.8281 20.7795V7.20953C32.8281 6.96953 33.0281 6.76953 33.2681 6.76953C33.5081 6.76953 33.7081 6.96953 33.7081 7.20953V20.7795C33.7081 21.0295 33.5081 21.2195 33.2681 21.2195Z" fill="white"/>
                <path key={16} data-index="16" d="M35.5494 27.0902C35.3094 27.0902 35.1094 26.8902 35.1094 26.6502V1.35015C35.1094 1.11015 35.3094 0.910156 35.5494 0.910156C35.7894 0.910156 35.9894 1.11015 35.9894 1.35015V26.6502C35.9894 26.8902 35.7894 27.0902 35.5494 27.0902Z" fill="white"/>
                <path key={17} data-index="17" d="M37.8306 24.1598C37.5906 24.1598 37.3906 23.9598 37.3906 23.7198V4.27984C37.3906 4.03984 37.5906 3.83984 37.8306 3.83984C38.0706 3.83984 38.2706 4.03984 38.2706 4.27984V23.7098C38.2706 23.9598 38.0706 24.1598 37.8306 24.1598Z" fill="white"/>
                <path key={18} data-index="18" d="M40.1119 21.2195C39.8719 21.2195 39.6719 21.0195 39.6719 20.7795V7.20953C39.6719 6.96953 39.8719 6.76953 40.1119 6.76953C40.3519 6.76953 40.5519 6.96953 40.5519 7.20953V20.7795C40.5519 21.0295 40.3619 21.2195 40.1119 21.2195Z" fill="white"/>
                <path key={19} data-index="19" d="M42.4009 19.7583C42.1609 19.7583 41.9609 19.5583 41.9609 19.3183V8.67828C41.9609 8.43828 42.1609 8.23828 42.4009 8.23828C42.6409 8.23828 42.8409 8.43828 42.8409 8.67828V19.3183C42.8309 19.5583 42.6409 19.7583 42.4009 19.7583Z" fill="white"/>
                <path key={20} data-index="20" d="M44.6783 24.1598C44.4383 24.1598 44.2383 23.9598 44.2383 23.7198V4.27984C44.2383 4.03984 44.4383 3.83984 44.6783 3.83984C44.9183 3.83984 45.1183 4.03984 45.1183 4.27984V23.7098C45.1183 23.9598 44.9183 24.1598 44.6783 24.1598Z" fill="white"/>
                <path key={21} data-index="21" d="M46.9595 21.2195C46.7195 21.2195 46.5195 21.0195 46.5195 20.7795V7.20953C46.5195 6.96953 46.7195 6.76953 46.9595 6.76953C47.1995 6.76953 47.3995 6.96953 47.3995 7.20953V20.7795C47.3995 21.0295 47.1995 21.2195 46.9595 21.2195Z" fill="white"/>
                <path key={22} data-index="22" d="M49.2408 19.7583C49.0008 19.7583 48.8008 19.5583 48.8008 19.3183V8.67828C48.8008 8.43828 49.0008 8.23828 49.2408 8.23828C49.4808 8.23828 49.6808 8.43828 49.6808 8.67828V19.3183C49.6808 19.5583 49.4808 19.7583 49.2408 19.7583Z" fill="white"/>
                <path key={23} data-index="23" d="M51.5181 16.8319C51.2781 16.8319 51.0781 16.6319 51.0781 16.3919V11.6119C51.0781 11.3719 51.2781 11.1719 51.5181 11.1719C51.7581 11.1719 51.9581 11.3719 51.9581 11.6119V16.3919C51.9581 16.6319 51.7681 16.8319 51.5181 16.8319Z" fill="white"/>
                <path key={24} data-index="24" d="M53.8111 15.3606C53.5711 15.3606 53.3711 15.1606 53.3711 14.9206V13.0806C53.3711 12.8406 53.5711 12.6406 53.8111 12.6406C54.0511 12.6406 54.2511 12.8406 54.2511 13.0806V14.9206C54.2511 15.1706 54.0511 15.3606 53.8111 15.3606Z" fill="white"/>
                <path key={25} data-index="25" d="M56.0884 15.3606C55.8484 15.3606 55.6484 15.1606 55.6484 14.9206V13.0806C55.6484 12.8406 55.8484 12.6406 56.0884 12.6406C56.3284 12.6406 56.5284 12.8406 56.5284 13.0806V14.9206C56.5284 15.1706 56.3284 15.3606 56.0884 15.3606Z" fill="white"/>
                <path key={26} data-index="26" d="M58.3697 15.3606C58.1297 15.3606 57.9297 15.1606 57.9297 14.9206V13.0806C57.9297 12.8406 58.1297 12.6406 58.3697 12.6406C58.6097 12.6406 58.8097 12.8406 58.8097 13.0806V14.9206C58.8097 15.1706 58.6097 15.3606 58.3697 15.3606Z" fill="white"/>
                <path key={27} data-index="27" d="M60.6509 16.8319C60.4109 16.8319 60.2109 16.6319 60.2109 16.3919V11.6119C60.2109 11.3719 60.4109 11.1719 60.6509 11.1719C60.8909 11.1719 61.0909 11.3719 61.0909 11.6119V16.3919C61.0909 16.6319 60.9009 16.8319 60.6509 16.8319Z" fill="white"/>
                <path key={28} data-index="28" d="M62.9283 16.8319C62.6883 16.8319 62.4883 16.6319 62.4883 16.3919V11.6119C62.4883 11.3719 62.6883 11.1719 62.9283 11.1719C63.1683 11.1719 63.3683 11.3719 63.3683 11.6119V16.3919C63.3683 16.6319 63.1783 16.8319 62.9283 16.8319Z" fill="white"/>
                <path key={29} data-index="29" d="M65.2213 15.7992C64.9813 15.7992 64.7812 15.5992 64.7812 15.3592V12.6392C64.7812 12.3992 64.9813 12.1992 65.2213 12.1992C65.4613 12.1992 65.6613 12.3992 65.6613 12.6392V15.3592C65.6613 15.5992 65.4613 15.7992 65.2213 15.7992Z" fill="white"/>
                <path key={30} data-index="30" d="M67.4986 15.3606C67.2586 15.3606 67.0586 15.1606 67.0586 14.9206V13.0806C67.0586 12.8406 67.2586 12.6406 67.4986 12.6406C67.7386 12.6406 67.9386 12.8406 67.9386 13.0806V14.9206C67.9386 15.1706 67.7386 15.3606 67.4986 15.3606Z" fill="white"/>
                <path key={31} data-index="31" d="M69.7798 15.3606C69.5398 15.3606 69.3398 15.1606 69.3398 14.9206V13.0806C69.3398 12.8406 69.5398 12.6406 69.7798 12.6406C70.0198 12.6406 70.2198 12.8406 70.2198 13.0806V14.9206C70.2198 15.1706 70.0198 15.3606 69.7798 15.3606Z" fill="white"/>
                <path key={32} data-index="32" d="M72.0611 15.3606C71.8211 15.3606 71.6211 15.1606 71.6211 14.9206V13.0806C71.6211 12.8406 71.8211 12.6406 72.0611 12.6406C72.3011 12.6406 72.5011 12.8406 72.5011 13.0806V14.9206C72.5011 15.1706 72.3111 15.3606 72.0611 15.3606Z" fill="white"/>
                <path key={33} data-index="33" d="M74.3384 18.2892C74.0984 18.2892 73.8984 18.0892 73.8984 17.8492V10.1392C73.8984 9.89922 74.0984 9.69922 74.3384 9.69922C74.5784 9.69922 74.7784 9.89922 74.7784 10.1392V17.8492C74.7784 18.0992 74.5884 18.2892 74.3384 18.2892Z" fill="white"/>
                <path key={34} data-index="34" d="M76.6314 16.8319C76.3914 16.8319 76.1914 16.6319 76.1914 16.3919V11.6119C76.1914 11.3719 76.3914 11.1719 76.6314 11.1719C76.8714 11.1719 77.0714 11.3719 77.0714 11.6119V16.3919C77.0714 16.6319 76.8714 16.8319 76.6314 16.8319Z" fill="white"/>
                <path key={35} data-index="35" d="M78.9088 15.3606C78.6688 15.3606 78.4688 15.1606 78.4688 14.9206V13.0806C78.4688 12.8406 78.6688 12.6406 78.9088 12.6406C79.1488 12.6406 79.3487 12.8406 79.3487 13.0806V14.9206C79.3487 15.1706 79.1488 15.3606 78.9088 15.3606Z" fill="white"/>
                <path key={36} data-index="36" d="M81.19 15.3606C80.95 15.3606 80.75 15.1606 80.75 14.9206V13.0806C80.75 12.8406 80.95 12.6406 81.19 12.6406C81.43 12.6406 81.63 12.8406 81.63 13.0806V14.9206C81.63 15.1706 81.43 15.3606 81.19 15.3606Z" fill="white"/>
                <path key={37} data-index="37" d="M83.4713 15.3606C83.2313 15.3606 83.0312 15.1606 83.0312 14.9206V13.0806C83.0312 12.8406 83.2313 12.6406 83.4713 12.6406C83.7113 12.6406 83.9113 12.8406 83.9113 13.0806V14.9206C83.9113 15.1706 83.7213 15.3606 83.4713 15.3606Z" fill="white"/>
            </svg>
        </div>
    )
}
export default Waveform;